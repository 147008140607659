import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
  ar: {
    // start home page
    home:" الرئيسية",
    buyCarsPage:"شراء",
    carRentalPage:"تاجير", 
    maintenanceCentersPage:"الصيانه ",
    SearchForCar: "ابحث عن سيارة",
    new: "جديد",
    AlmostNew: "كسر زيرو",
    used: "مستعمل",
    carMarker: "المصنع",
    carModel: " موديل السياره",
    selectGovernorate: "اختر المحافظة",
    buyCars: "بيع و شراء السيارات",
    maxPrice: "السعر الأعلى",
    lowestPrice: "السعر الأدنى",
    searchForACar: "ابحث عن سيارة",
    searchByCarBrands: "بحث بماركات السيارات",
    exploreSections: " استكشف الأقسام",
    latestArticles: "أحدث المقالات",
    allArticles: "كل المقالات",
    lookingForABuyerForYourCar: "تبحث عن مشتري لسيارتك؟",
    CarNewsInEgypt: "تريد معرفة أحدث اخبار السيارات في مصر؟",
    carNews: "أخبار السيارات ",
    sellMyCar: " بيع سيارتي ",
    audi: "اودي",
    mercedesBenz: "مرسيدس بنز",
    peugeot: "بيجو",
    rangeRover: "رانج روفر",
    jeep: "جيب",
    porsche: "بورش",
    karry: "كاري",
    gac: "جاك",
    toyota: "تويوتا",
    kia: "كيا",
    mg: "ام جي",
    bmw: "بي ام دابليو",
    hyundai: "هيونداي",
    ford: "فورد",
    volvo: "فولفو",
    searchByCarBodyTypes: "بحث بأنواع السيارات",
    sedan: "سيدان",
    quarterTransfer: "ربع نقل",
    convertible: " سيارات مكشوفة",
    hatchback: "هاتشباك",
    coupe: "كوبيه",
    crossover: " كروس اوفر",
    bus: "اتوبيس",
    miniVan: " ميني فان",
    pickup: " بيك اب",
    stationWagon: " ستيشن واجن",
    carRental: "تأجير السيارات",
    maintenanceCenters: "مراكز الصيانة",
    spareParts: "قطع الغيار",
    rescueWinch: "ونش إنقاذ",
    howToUseTheSouqCar:"كيف تستخدم سوق . كار؟ ",
    howToUse:"شرح الاستخدام",
    featuredAds:"الاعلانات المميزة",
    EGP:"ج.م",
     // end home page
    //start user edit
   
    myAccount: "الملف الشخصي",
    newAd: "أضف إعلان",
    myAds: "إعلاناتي",
    messages: "الرسائل",
    membershipPackages: "باقات العضويات",
    logout: "تسجيل الخروج",
    welcome: "مرحبا بك",
    description:
      "يمكنك تعديل بيانات ملفك الشخصي ومتابعة اعلانتك ورسائلك وباقاتك المشترك فيها",
    adsCount: "عدد الإعلانات",
    enabledAds: "الاعلانات النشطة",
    yourMembership: "نوع العضوية",
    unreadMessages: "الرسائل الغير مقروءة",
    professionalpackage: "باقة محترف",
    basicInformation: "البيانات الاساسية",
    basicInformationparagraph:
      "لن يظهر للزوار من بياناتك سوا الاسم والهاتف والواتساب فقط",
    email: "البريد الإلكتروني",
    name: "الإسم",
    mobile: "الموبايل",
    whatsapp: "واتس اب",
    save: "حفظ",
    deactivateYourAccount: "تعطيل الحساب",
    changePassword: "تغيير كلمة المرور",
    changePasswordparagraph:
      "احرص على ان تكون كلمة المرور قوية تحتوي ع ارقام وحروف ورموز ولا تشاركها مع احد",
    currentPassword: "كلمة المرور الحالية",
    newPassword: "كلمة المرور الجديدة",
    passwordConfirmation: "تأكيد كلمة المرور",
    //end user edit
    Keywords: "كلمات مفتاحية - رقم الاعلان",
    location: "الموقع",
    egypt: "مصر",
    carMaker: "مصنع السيارة",
    carBody: "هيكل السيارة",
    carFuel: "نوع الوقود",
    carTransmission: "ناقل الحركة",
    color: "اللون",
    kilometerage: "  عدد الكيلومترات",
    carCondition: "حالة السياره",
    engine: "سعه المحرك",
    productionYear: "سنه التصنيع",
    extraFeatures: "المميزات الاضافية",
    price: "السعر",
    payment: "طرق الدفع",
    from: "من",
    to: "الى",
    EGP: "ج.م",
    km: "كم",
    ex: "مثال",
    plases: ["الجميع", "القاهره", "الجيره", "الاسكنداريه", "المنيا"],
    carMakerList: [
      "الجميع",
      "لادا",
      "سينوفا",
      "دفسك",
      "هايما",
      "شاهين",
      "كينغ لونغ",
      "كارى",
      "اسبيرانزا",
      "استون مارتين",
      "النصر",
      "ام جى",
      "اوبل",
      "اودى",
      "بروتون",
      "بورش",
      "بى ام دابليو",
      "بيجو",
      "تويوتا",
      "جى ام سي",
      "جيب",
      "جيلى",
      "دايو",
      "رينو",
      "سكودا",
      "سوزوكى",
      "سيات",
      "سيتروين",
      "شيرى",
      "شيفروليه",
      "فورد",
      "فولفو",
      "فولكس واغن",
      "فيات",
      "فيراري",
      "كيا",
      "مازدا",
      "هونداى",
    ],
    carFuelList: ["بنزين", "ديزيل(سولار)", "كهرباء", "الغاز الطبيعى", "أخرى"],
    carTransmissionList: ["أوتوماتيك", "يدوى", "cvt"],
    carConditionList: ["جديد", " كسر زيرو", "مستعمل", "خرده"],
    engineList: [
      "cc 1-999",
      "cc 1000-1399",
      "cc 1400-1599",
      "cc 1600",
      "cc 1601-2000",
      "cc 2001-2800",
      "cc 2800-7500",
      "cc 4000",
      "cc 3000",
      "cc 5700",
      "cc 4400",
      "miles 350",
    ],
    extraFeaturesList: [
      "نطام فرامل ABS",
      "كاميرا خلفية",
      "شاشه تعمل باللمس",
      "مثبت سرعه",
      "حساس ركن",
      "EBD",
      "اطارات خاصه",
      "تنيه/نضام مضاد للسرقة",
      "وسائد هوائيه",
      "مرايا كهربائيه",
      "زجاج كهربائى",
      "مقاعد جلد",
      "شاحن يو اس بس",
      "مصابيح ضبابيه",
      "باور ستيرينج",
      "بلوتوث",
      "مدخل aux",
      "قفل مركزى",
      "تكييف",
      "راديو اف ام",
    ],
    rentalTerm:"مده الايجار",
    rentalTermList:["الجميع" , 'يومى','أسبوعى','شهرى','سنوى'],
    driverStatus:'السائق',
    driverStatusList:['الجميع','مع سائق' , 'بدون سائق'],
    service:"نوع الخدمة",
    serviceList:["صيانة كهربائيه","التلميع والتشطيبات","ميكانيكا","صيانة اطارات",
    "حدادة و دهان سيارات" ,"تبريد و تكييف","صيانة زجاج السيارة","علبة السرعة","تيست"],
    productType: "نوع المنتج",
    productTypeList:['كاوتش(عجلات)','بطاريات','زيوت','اكسسوارات','جسم السيارة',
    'فوانيس','مواتير وفتيس','فرامل وعفشة',
    'صالون واجزاء داخليه','حساسات و الكترونيات','زجاج السيارة' ,'فلاتر',' انصاف سيارات',' جنوط اصلية'],
    productStatus:"حالة المنتج",
    productStatuslist:['جديد','مستعمل'],
    advancedSearch:"البحث المتقدم",
    search: "بحث",
    clear: "حذف الفلاتر",
  
    //view nav componennts
    show: "عرض",
    order: "ترتيب",
    orderList: [
      "من الأحدث للأقدم",
      "من الأقدم للأحدث",
      "السعر الأعلى أولا",
      "السعر الأقل أولا",
    ],
     //car cart
     saveAd :'حفظ الاعلان',
     removeAd:"حذف الاعلان",
     share:"مشاركه",
     rent:"ايجار",
     //footer
    souqcar:"سوق كار",
    aboutus:"من نحن",
    contactus:"تواصل معنا",
    needhelp:"مساعدة",
    faq:"الأسئلة الشائعة",
    privacypolicy:"سياسة الخصوصية",
    termsofuse:"شروط الاستخدام",
    famouscarmakers:"اشهر مصنعي السيارات",
    mercedes:"مرسيدس بنز",
    fiat:"فيات",
    
    signout:"خروج",
    signin:"دخول",
    news:"الأخبار"

  },
  en: {
    // start home page
    home:"Home",
    buyCarsPage:"Buy ",
    carRentalPage:"Rental ",
    maintenanceCentersPage:"Maintenance ",
    SearchForCar: "Search for a car",
    new: "New",
    AlmostNew: "Almost New",
    used: "Used",
    carMarker: "Car Marker",
    carModel: "Car Model",
    selectGovernorate: "Select Governorate",
    buyCars: "Buy Cars",
    maxPrice: "Max Price",
    lowestPrice: "Lowest Price",
    searchForACar: "Search for a car",
    advancedSearch: "Advanced Search",
    searchByCarBrands: "Search by car brands",
    exploreSections: "Explore Sections",
    latestArticles: "Latest Articles",
    allArticles: "all articles",
    CarNewsInEgypt: "Want to know the latest car news in Egypt?",
    carNews: "Car News",
    sellMyCar: "Sell My Car",
    lookingForABuyerForYourCar: "Looking for a buyer for your car?",
    audi: "Audi",
    mercedesBenz: "Mercedes-Benz",
    peugeot: "Peugeot",
    rangeRover: "range rover",
    jeep: "Jeep",
    porsche: "Porsche",
    karry: "Karry",
    gac: "Gac",
    toyota: "Toyota",
    kia: "Kia",
    mg: "MG",
    bmw: "BMW",
    hyundai: "Hyundai",
    ford: "Ford",
    volvo: "Volvo",
    searchByCarBodyTypes: "Search by car Body types",
    sedan: "Sedan",
    quarterTransfer: "Quarter transfer",
    convertible: "Convertible",
    hatchback: "Hatchback",
    coupe: "Coupe",
    crossover: "Crossover",
    bus: "Bus",
    miniVan: "Mini van",
    pickup: "Pickup",
    stationWagon: "station wagon",
    carRental: " Car Rental",
    maintenanceCenters: "Maintenance Centers",
    spareParts: "Spare Parts",
    rescueWinch: "Rescue Winch",
    howToUseTheSouqCar: "How to use the Souq.Car? ",
    howToUse: "How to use",
    featuredAds: "Featured ads",
    EGP:"EGP",
    // end home page
    //start user edit
    myAccount: "My Account",
    newAd: "New Ad",
    myAds: "My Ads",
    messages: "Messages",
    membershipPackages: "Membership Packages",
    logout: "Logout",
    welcome: "Welcome",
    description:
      "You can modify your profile info and follow up on your ads, messages and membership packages",
    adsCount: "Ads Count",
    enabledAds: "Enabled Ads",
    yourMembership: "Your Membership",
    unreadMessages: "Unread Messages",
    professionalpackage: "professional package",
    basicInformation: "Basic Information",
    basicInformationparagraph:
      "Only your name, phone and WhatsApp will be visible to visitors",
    email: "Email",
    name: "Name",
    mobile: "Mobile",
    whatsapp: "Whatsapp",
    save: "Save",
    deactivateYourAccount: "Deactivate Your Account",
    changePassword: "Change Password",
    changePasswordparagraph:
      "Make sure that the password is strong and contains numbers, letters and symbols, and do not share it with anyone",
    currentPassword: "Current Password ",
    newPassword: "New Password",
    passwordConfirmation: "Password Confirmation",
    //end user edit
    //  filter components
    Keywords: "Keywords - Ad No",
    location: "Location",
    egypt: "Egypt",
    carMaker: "Car Maker",
    carBody: "Car Body",
    carFuel: "Car Fuel Type",
    carTransmission: "Car Transmission Type",
    color: "Color",
    kilometerage: "Kilometerage",
    carCondition: "Car Condition",
    engine: "Engine",
    productionYear: "Production Year",
    extraFeatures: "Extra Features",
    price: "Price",
    payment: "Payment Type",
    from: "From",
    to: "To",
    EGP: "EGP",
    km: "km",
    ex: "ex",
    plases: ["All", "Cairo", "Giza", "Alexandria", "Minia"],
    carMakerList: [
      "All",
      "Lada",
      "Senova",
      "dfsk",
      "HAIMA",
      "Shaheen",
      "King Long",
      "carry",
      "Speranza",
      "Aston Martin",
      "Al Nasr",
      "MG",
      "Opel",
      "Audi",
      "Proton",
      "Porsche",
      "BMW",
      "Peugeot",
      "Toyota",
      "GMC",
      "jeep",
      "Geely",
      "Daewoo",
      "Renault",
      "Skoda",
      "Suzuki",
      "Seat",
      "Citroen",
      "Chert",
      "Chevrolet",
      "Ford",
      "Volvo",
      "Volkswagen ",
      "Fiat",
      "Ferrari",
      "Kia",
      "Mazda",
      "Hyundai",
    ],
    carFuelList: ["Petrol", "Diesel", "Electric", "Gas", "Other"],
    carTransmissionList: ["Automatic", "Manual", "CVT"],
    carConditionList: ["New", "Almost New", "Used", "Scrap"],
    engineList: [
      "cc 1-999",
      "cc 1000-1399",
      "cc 1400-1599",
      "cc 1600",
      "cc 1601-2000",
      "cc 2001-2800",
      "cc 2800-7500",
      "cc 4000",
      "cc 3000",
      "cc 5700",
      "cc 4400",
      "miles 350",
    ],
    extraFeaturesList: [
      "ABS brake system",
      "Back camera",
      "Touch screen",
      "Cruise control",
      "corner sensors",
      "EBD",
      "Special frames",
      "Alarm/Anti-thefh system",
      "Airbags",
      "Electric mirrors",
      "Electric glass",
      "leather seats",
      "USB charger",
      "Mist lanterns",
      "Power steering",
      "Bluetooth",
      "Aux audio input",
      "Center locl",
      "Conditioning",
      "FM radio",
    ],
    search: "Search",
    clear: "Clear Filter",
    rentalTerm:"Rental Term",
    rentalTermList:["All" , 'Daily','Weekly','Monthly','Yearly'],
    driverStatus:'driver status',
    driverStatusList:['All','With a Driver' , 'Without Driver'], 
    service:"Service",
    serviceList:[
      'Car ekectrical maintenance','Polishing and finishing','Mechanical','Tire maintenance',
      'Car smithing and painting','Refrigeration & Air Conditioning','Car glass maintenance','Gearbox','test'

    ],
    productType: "product type",
    productTypeList:['Wheels','Batteries','Oils','Accessories','Car body',
    'Lanterns','Motors and fuses','Brakes and furniture',
    'Salon and interior parts','Sensors and electronics','Car glass' ,'filters','Half cars','Orginal rims'],
    productStatus:"Product Status",
    productStatuslist:['New','Used'],
    
    //view nav componennts
    show: "Show",
    order: "Order",
    orderList: [
      "Newest First",
      "Oldest First",
      "Highest Price First",
      "Lowest Price First",
    ],
    
     //car cart
     saveAd :'Save ad',
     removeAd:"remove ad",
     share:"Share",
     rent:"Rent",
    //footer
    souqcar:"Souq Car",
    aboutus:"About Us",
    contactus:"Contact Us",
    needhelp:"Need Help",
    faq:"FAQ",
    privacypolicy:"Privacy Policy",
    termsofuse:"Terms of Use",
    famouscarmakers:"Famous Car Makers",
    mercedes:"Mercedes-Benz",
    fiat:"Fiat",
    signout:"Sign out",
    signin:"Sign in",
    news:"News"
  },
});
export default strings;
